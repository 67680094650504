exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-clinica-js": () => import("./../../../src/pages/a-clinica.js" /* webpackChunkName: "component---src-pages-a-clinica-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tratamentos-dermatologia-cirurgica-index-js": () => import("./../../../src/pages/tratamentos/dermatologia_cirurgica/index.js" /* webpackChunkName: "component---src-pages-tratamentos-dermatologia-cirurgica-index-js" */),
  "component---src-pages-tratamentos-dermatologia-clinica-index-js": () => import("./../../../src/pages/tratamentos/dermatologia_clinica/index.js" /* webpackChunkName: "component---src-pages-tratamentos-dermatologia-clinica-index-js" */),
  "component---src-pages-tratamentos-dermatologia-estetica-index-js": () => import("./../../../src/pages/tratamentos/dermatologia_estetica/index.js" /* webpackChunkName: "component---src-pages-tratamentos-dermatologia-estetica-index-js" */),
  "component---src-pages-tratamentos-index-js": () => import("./../../../src/pages/tratamentos/index.js" /* webpackChunkName: "component---src-pages-tratamentos-index-js" */),
  "component---src-pages-tratamentos-tratamentos-capilares-index-js": () => import("./../../../src/pages/tratamentos/tratamentos_capilares/index.js" /* webpackChunkName: "component---src-pages-tratamentos-tratamentos-capilares-index-js" */)
}

